import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import createCompressor from "redux-persist-transform-compress"
import { createWhitelistFilter } from "redux-persist-transform-filter"
import localForage from "localforage"

// state to persist
const whitelists = [
  // 👤 user properties to cache
  createWhitelistFilter("user", [
    "upn",
    "defaultRole",
    "currentRole",
    "currentTagA",
    "currentTagB",
    "currentTagC",
    "configurator",
  ]),
  createWhitelistFilter("ui", ["menuSize"]),
  createWhitelistFilter("locale", ["currentLanguageCached"]),
  createWhitelistFilter("documents", ["relatedDocuments"]),
]

const createPersistenceConfig = (encryptor) => {
  const persistConfig = {
    key: "root",
    storage: localForage, // use localforage for handling saving data to the client most efficently with fallbacks
    stateReconciler: autoMergeLevel2, // merge cached state tree two levels down
    version: process.env.VERSION, // cache gets invalidated if package.json version is changed for the webapp
    whitelist: ["user", "locale", "ui", "documents"], // whitelist of state subtrees to cache
    transforms: [...whitelists, encryptor, createCompressor()],
  }

  return persistConfig;
}

export default createPersistenceConfig
