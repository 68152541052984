import { compose, createStore } from "redux"
import {persistStore} from 'redux-persist';
import createPersistenceConfig from "./persist-config"
import { createEncryptionTransform } from "../services/encryption/createEncryptionTransform"
import { DEFAULT_LANGUAGE } from "../globals"
import messages from "../locales/en.json"
import createRootReducer from "./reducers"
import middleware from "./middleware"

const enhancers = []
const production =
  process.env.NODE_ENV === "production" && process.env.BUILD_CONFIGURATION === "Production"
if (production === false) {
  // only enable redux devtools in development environments
  // ? https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension())
  }
}

export const configureStore = ({
 encryptionKey
}) => {
  const encryptor = createEncryptionTransform(encryptionKey.key);

  const initialState = {
    intl: {
      defaultLocale: DEFAULT_LANGUAGE,
      locale: DEFAULT_LANGUAGE,
      messages,
    },
  }

  const config = createPersistenceConfig(encryptor);
  const rootReducer = createRootReducer(config)

  // compose middleware with devtools
  const composedEnhancers = compose(middleware, ...enhancers)

  const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers,
  );

  const persistor = persistStore(store);
  window.persistor = persistor

  return {
    store,
    persistor,
  };
};


// #region application state flow
/**

 API Calls
 ▲
 │
 ▼
 Action         Action(s)    ┌──────────────────────┐
 ┌────────▶  Creators  ───────────────────┼──▶  Root Reducer     │
 │                                        │ ╔═══╗╔═══╗╔═══╗╔═══╗ │
 ┌──────────────┐                                 │ ║   ║║   ║║   ║║   ║ │
 │    React     │                                 │ ║┏━━╩╩━━━╩╩━━━╩╩━━┓║ │
 │  Components  │                                 │ ║┃ Slice Reducers ┃║ │
 └──────────────┘                                 │ ║┗━━╦╦━━━╦╦━━━╦╦━━┛║ │
 ▲                                        │ ║   ║║   ║║   ║║   ║ │
 │                                        │ ╚═══╝╚═══╝╚═══╝╚═══╝ │
 │                                        └──────────┬───────────┘
 │                                                   │
 │                                                   ▼
 └────────── Selectors  ◀──────────────────  Application State

 */
// #endregion
